import React from "react";
import { graphql, Link } from "gatsby";
import { Layout } from "../components/Layout";
import { ArrowRight, ArrowLeft } from "react-feather";

export default ({ data, pageContext }) => {
  const { currentPage, numPagesForTagListing, tag } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPagesForTagListing;
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  return (
    <Layout pageTitle={`etiket "${tag}" - yazılarım`} path={`/blog/tag/${tag}`}>
      <div className="blog-list">
        <h1>yazılarım / etiket: {tag}</h1>

        {data.allFile.nodes.map(node => (
          <article className="blog-post-summary">
            <div className="post-meta-data">
              <span className="post-date">{node.childMdx.fields.date}</span>
              <span className="meta-data-seperator">&#8226;</span>
              <div className="post-tag-list">
                {node.childMdx.frontmatter.tags.map((tag, index) => {
                  const isLastTag = node.childMdx.frontmatter.tags[index + 1]
                    ? false
                    : true;
                  if (!isLastTag) {
                    return (
                      <span>
                        <Link to={`/blog/tag/${tag}`}>{tag}</Link>,{" "}
                      </span>
                    );
                  } else {
                    return <Link to={`/blog/tag/${tag}`}>{tag}</Link>;
                  }
                })}
              </div>
            </div>
            <Link to={`/blog/${node.childMdx.fields.slug}`}>
              <h2 className="post-title">{node.childMdx.frontmatter.title}</h2>
            </Link>
            <p>{node.childMdx.excerpt}</p>
            <div className="read-more">
              <Link to={`/blog/${node.childMdx.fields.slug}`}>oku</Link>
            </div>
          </article>
        ))}

        {!(isFirst && isLast) && (
          <nav className="pagination">
            <ul>
              {!isFirst && (
                <li className="previousPage">
                  <Link to={`/blog/tags/${tag}/${prevPage}`} rel="prev">
                    <ArrowLeft width="18" height="18" viewBox="0 0 20 20" />
                  </Link>
                </li>
              )}
              {Array.from({ length: numPagesForTagListing }, (_, i) => (
                <li>
                  <Link
                    key={`pagination-number${i + 1}`}
                    to={`/blog/tags/${tag}/${i === 0 ? "" : i + 1}`}
                  >
                    {i + 1}
                  </Link>
                </li>
              ))}
              {!isLast && (
                <li className="nextPage">
                  <Link to={`/blog/tags/${tag}/${nextPage}`} rel="next">
                    <ArrowRight width="18" height="18" viewBox="0 0 20 20" />
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        )}
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query blogListByTagQuery($skip: Int!, $limit: Int!, $tag: String!) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "blog" }
        childMdx: { frontmatter: { tags: { eq: $tag } } }
      }
      sort: { fields: childMdx___fields___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        childMdx {
          fields {
            slug
            date(formatString: "MMMM DD, YYYY", locale: "tr")
          }
          frontmatter {
            title
            tags
          }
          id
          excerpt(pruneLength: 250)
        }
      }
    }
  }
`;
